<template>
  <ul class="tw-flex tw-gap-7.5">
    <li
      v-for="item in APP_MAIN_MENU"
      :key="item.label"
      class="tw-italic tw-uppercase tw-font-bold tw-leading-500"
    >
      <nuxt-link-locale :to="item.to" @click="emit('navigate')">
        {{ t(item.label) }}
      </nuxt-link-locale>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { APP_MAIN_MENU } from '~/data/config/app-main-menu.config.js';

const emit = defineEmits<{
  navigate: [];
}>();

const { t } = useI18n();
</script>
