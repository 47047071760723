import type { MenuItemType } from '~/shared/types/menu-item.type';

export const APP_MAIN_MENU: MenuItemType[] = [
  {
    label: 'menu.header.ranking',
    to: '/ranking',
  },
  {
    label: 'menu.header.news',
    to: '/news',
  },
  {
    label: 'menu.header.articles',
    to: '/analytics',
  },
  {
    label: 'menu.header.defight',
    to: '#',
  },
];
